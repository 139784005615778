.mainArea {
    width: 100%;
    height: 94vh;
    margin-top: 30px;
    overflow: auto;
}
.mainArea::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}
.mainArea::-webkit-scrollbar-thumb {
    background: #FBBFA6;
    border-radius: 5px;
}