.textInput {
    background: #f7f7f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 460px;
    height: 50px;
    font-size: 16px;
    color: #717171;
    border: 0px solid transparent;
    padding-left: 10px;
    caret-color: #fe814f;
}

.textInput:focus {
    outline: 1px solid #fe814f;
}
