.mainContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Montserrat";
  border-radius: 5px;
  outline: 0px;
  background-color: #ffff;
}
.closeIcon {
  position: absolute;
  top: 24px;
  right: 20px;
  cursor: pointer;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #555555;
  margin-top: 29px;
}
.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #555555;
}
.noBtn {
  background: #999999;
  margin-right: 17px;
}
.buttons button {
  width: 210px;
  height: 50px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.yesBtn {
  background: linear-gradient(98.82deg, #ffc3ab -1.58%, #fe814f 123.55%);
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 44px 34px 44px;
}
