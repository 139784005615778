.select {
    width: 460px;
    height: 50px;
    background: #f7f7f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #717171;
    border: 0px solid transparent;
    outline: none;
    cursor: pointer;
}
.select:focus {
    outline: 1px solid #fe814f;
}
.option {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Montserrat';
    background-color: #f7f7f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.option:hover {
    color: #ffffff;
    background: linear-gradient(98.82deg, #ffc3ab -1.58%, #fe814f 123.55%);
}
