.table {
  border-collapse: collapse;
  width: calc(100% - 10px);
  border-radius: 5px;
  overflow: hidden;
  padding-left: 29px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #747474;
  margin: 5px;
}

.paginationArea {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 40px;
}

.tableColumn th {
  padding: 20px 8px;
  position: relative;
}

.tableColumn th:first-child {
  padding-left: 22px;
}

.table thead tr {
  background-color: #ffffff;
  text-align: left;
  border-bottom: 1px solid #787878;
  transition: 0.3s;
  font-weight: 600;
}
.tableBody {
  overflow: scroll;
}
.tableBody td {
  padding: 10px 8px;
}

.tableBody td:first-child {
  padding-left: 22px;
}

.table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.arrows {
  position: absolute;
  bottom: 22px;
  left: 104px;
}

.arrows2 {
  position: absolute;
  bottom: 22px;
  left: 77px;
}
