.block {
    width: 30%;
    height: 100%;
    overflow: auto;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
}
.wrap {
    width: 100%;
    overflow: auto;
}
.wrap::-webkit-scrollbar {
    width: 3px;
}
.wrap::-webkit-scrollbar-thumb {
    background: #FBBFA6;
    border-radius: 5px;
}
h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #757575;
    text-align: center;
    padding-top: 35px;
}

