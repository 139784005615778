.mainArea {
  width: 100%;
  height: 100vh;
  margin-top: 30px;
  overflow: auto;
}
.mainArea::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.mainArea::-webkit-scrollbar-thumb {
  background: #fbbfa6;
  border-radius: 5px;
}
.block {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #ffffff;
}
.name {
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.header {
  width: 100%;
  height: 75px;
  background: linear-gradient(161.79deg, #fbb59a -34.78%, #ffdbcd 150.13%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px 0 36px;
}
.closeIcon {
  cursor: pointer;
  width: 22px;
  height: 22px;
}
.textHelper {
  color: tomato;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 16px 25px 32px;
  gap: 50px;
}
.contentButton {
  width: 210px;
  height: 50px;
  background: linear-gradient(98.82deg, #ffc3ab -1.58%, #fe814f 123.55%);
  border-radius: 5px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
}
.wrap {
  display: flex;
}
.content {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  /* overflow: scroll; */
}
.frame {
  width: 100%;
  height: 100%;
  background-color: #f7f7f8;
  border-radius: 5px;
}
.lineChart {
  padding-top: 30px;
  margin-left: 5px;
}
.input {
  width: 100%;
  margin-top: 6px;
}
.imagesWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 16px 0 29px;
  margin-top: 24px;
}
.formContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  background-color: #ffffff;
  padding: 33px 16px 25px 29px;
}
.formRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.formInfo {
  display: flex;
  gap: 18px;
  align-items: center;
  color: #747474;
  font-size: 16px;
  width: 100%;
}
.selectInfo {
  position: absolute;
  display: flex;
  gap: 18px;
  align-items: center;
  color: #747474;
  font-size: 16px;
  width: 100%;
  padding: 0 16px 0 29px;
  top: 12px;
  left: 18px;
  z-index: 9;
}
.selectInfo svg {
  width: 22px;
  height: 22px;
}
.inputColumn {
  width: 100%;
}
.icons {
  width: 22px;
  height: 22px;
}
.wrapper {
  display: flex;
  height: calc(100% - 75px);
}
.selectWrap {
  padding: 0 13px 0 28px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 45px);
  background: linear-gradient(180deg, #ffffff 0%, #f3f3f3 100%);
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  border-radius: 5px;
  height: 60px;
  margin: 0 16px 0 29px;
  color: #555555;
  align-items: center;
}
.activeArrow {
  transform: translate(0, 100%) rotate(-180deg);
  position: absolute;
  top: 12px;
  right: 30px;
}
.iconWrap {
  display: flex;
  gap: 17px;
}
.select {
  width: 100%;
  padding-left: 52px;
}
.contentScroll {
  /* overflow: auto; */
  /* height: calc(100vh - 100px); */
}
.contentScroll::-webkit-scrollbar {
  width: 3px;
}
.contentScroll::-webkit-scrollbar-thumb {
  background: #fbbfa6;
  border-radius: 5px;
}
.disabledBtn {
  background: linear-gradient(133.29deg, #ffba9e -28.13%, #ffe5da 192.16%);
  opacity: 0.4;
  cursor: auto;
}
.paymentInput {
  width: 100%;
  padding-left: 55px;
}
.paymentInputColumn {
  padding: 0 16px 25px 29px;
}
.paymentInputWrap {
  position: relative;
}
