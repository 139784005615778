.container {
  width: 310px;
  height: 220px;
}
.dropzone {
  background: #f7f7f8;
  border: 1px dashed #555555;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #717171;
}
.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #717171;
}

/* .thumb {
    display: 'inline-flex';
    border-radius: 2px;
    border: '1px solid #eaeaea';
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100%;
    height: 100%;
    padding: 4px;
    box-sizing: 'border-box';
}; */
.thumb {
  width: 100%;
  height: 100%;
}
.thumbWrap {
  position: absolute;
  top: 1px;
  width: 310px;
  height: 220px;
  background-size: cover;
}
section {
  position: relative;
}
ul {
  list-style-type: none;
}
.fileName {
  margin-top: 10px;
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #555555;
  display: flex;
  flex-direction: column;
}
