.wrapper {
    width: 100%;
    box-shadow: -1px 2px 7px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
}
.wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 48px;
    width: 100%;
    background-color: #ffffff;
    padding: 0 16px 0px 29px;
    position: absolute;
    z-index: 9;
    top: 90px;
}
.wrapRow {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}
.wrapColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.formInfo {
    display: flex;
    gap: 12px;
    align-items: center;
    color: #747474;
    font-size: 16px;
    width: 100%;
    margin-bottom: 8px;
    color: #555555;
}
.datePicker {
    width: 100%;
    background: #F7F7F8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.datePicker fieldset{
    border: none;
    outline: none;
}
.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 45px 0px 30px 0px;
    gap: 20px;
}
.buttons button {
    width: 142px;
    height: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.cancelBtn {
    background: #999999;
}
.applyBtn {
    background: linear-gradient(98.82deg, #FFC3AB -1.58%, #FE814F 123.55%);
}
.inputStyle {
    width: 100%!important;
}
.select {
    width: 100%;
    margin-top: 10px;
}
.textHelper {
    margin-top: 20px;
    color: tomato;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
}

