.tabsContainer {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 15px 10px 20px 10px;
}
.active {
    background: linear-gradient(98.82deg, #FFC3AB -1.58%, #FE814F 123.55%)!important;
    border-radius: 10px;
    color: #FFFFFF!important;
}

.tabsContainer button {
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    border-radius: 10px;
    background: #FFFFFF;
    color: #747474;
    padding: 11px 17px 15px 17px;
    white-space: nowrap;
    width: 100%;
}