.mainContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Montserrat';
    border-radius: 5px;
    outline: 0px;
    background-color: #FFFF;
    max-width: 530px;
}
.closeIcon {
    position: absolute;
    top: 24px;
    right: 20px;
    cursor: pointer;
}
.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #555555;
    margin-top: 29px;
}
.text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #555555;
}
.addBtn {
    color: #FE966C!important;
    background: linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.buttons button {
    width: 142px;
    height: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.yesBtn {
    background: linear-gradient(98.82deg, #FFC3AB -1.58%, #FE814F 123.55%);
}
.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 30px 0px;
    gap: 20px;

}
.inputColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 460px;
}
.icon {
    width: 20px;
    height: 20px;
}
.formRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
    gap: 20px;
}
.formInfo {
    display: flex;
    gap: 18px;
    align-items: center;
    font-size: 16px;
}
.formInfo p {
    white-space: nowrap;
    width: 110px;
    color: #555555;
}
.input {
    width: auto!important;
}
.formColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
}
.tabs {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    padding: 0px 45px;
}
.tab {
    width: 125px;
    height: 35px;
    border-radius: 5px;
    border: 2px solid #FE9971;
    color: #FE9971;
    background-color: #FFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
.deleteBtn {
    background: #999999;
    border-radius: 5px;
    color: #FFFFFF;
}
.disabled {
    opacity: 0.5;
}
.active {
    background: linear-gradient(98.82deg, #FFC3AB -1.58%, #FE814F 123.55%);
    border: none;
    color: #FFFFFF;
}
.textHelper {
    color: tomato;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
}