.sidebar {
    background: linear-gradient(178.31deg, #F4C2AE 1.43%, #FFD9CA 109.55%);
    border-radius: 5px;
    /* min-width: 300px; */
    width: 25%;
    height: 94vh;
    margin: 30px 0px 0 0;
}

.listMenu {
    margin-top: 20px;
    padding-left: 15px;
}

.logo {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img {
    height: 250px;
    z-index: 1;
}
.logout {
    /* height: 20px; */
    position: relative;
}
.logoutWrap {
    display: flex;
    margin-left: 25px;
    margin-bottom: 25px;
    z-index: 999;
    position: fixed;
    bottom: 36px;
    cursor: pointer;
}
.logoutWrap span {
    font-size: 18px;
    line-height: 22px;
    color: #DB7654;
    margin-left: 21px;
}
