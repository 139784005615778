.wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
    background-color: #ffffff;
    padding: 0 16px 25px 29px;
}
.block {
    width: 33%;
    height: 85px;
    background: linear-gradient(98.82deg, #FFC3AB -1.58%, #FE814F 123.55%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
}
.img {
    width: 56px;
    height: 56px;
    background: #FFFFFF;
    border-radius: 50%;
    position: relative;
    margin: 15px 18px 14px 8px;
}
.icon {
    position: absolute;
    top: 13px;
    left: 13px;
}
.titlesWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}
.subTitle {
    margin-top: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}