.datePickerWrap {
    width: 50%;
    margin-right: 20px;
    height: 50px;
    margin-bottom: 10px;
}
.datePicker {
    background: #FFFF;
}
.paginationArea {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 100%;
}

.table {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.9em;
    min-width: 400px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    font-size: 18px;
    padding-left: 29px;
}

.tableColumn th {
    padding: 20px 8px;
    color: #555555;
    line-height: 22px;
}

.tableColumn th:first-child {
    padding-left: 22px;
}

.table thead tr {
    background-color: #F7F7F8;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #dddddd;
    transition: 0.3s;
}

.tableBody td {
    padding: 10px 8px;
}

.tableBody td:first-child {
    padding-left: 22px;
}

.table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-of-type(odd) {
    background-color: #fbfbfb;
}

.table tbody tr:last-of-type {
    border-bottom: 3px solid #f3f3f3;
}

.table tbody tr:hover {
    background: linear-gradient(90.94deg, #f6c7b4 31.54%, #fabba2 74.58%);
    cursor: pointer;
    color: #ffffff;
}

.userInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.createUserButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    width: 210px;
    height: 50px;
    background: linear-gradient(0deg, #f58f6b, #f58f6b), #ffffff;
    border-radius: 5px;
    cursor: pointer;
    gap: 7px;
}

.arrowWrap {
    display: flex;
    align-items: center;
    gap: 10px;
}
