.paginationArea {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 100%;
}

.table {
    border-radius: 5px;
    border-collapse: collapse;
    width: 100%;
    min-width: 400px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    font-size: 18px;
    padding-left: 29px;
    margin-top: 20px;
}

.tableColumn th {
    padding: 20px 8px;
}

.tableColumn th:first-child {
    padding-left: 22px;
}

.table thead tr {
    background: #F7F7F8;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
    color: #999999;
    text-align: left;
    border-bottom: 1px solid #dddddd;
    transition: 0.3s;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    height: 50px;
}

.tableBody td {
    padding: 10px 8px;
}

.tableBody td:first-child {
    padding-left: 22px;
}

.table tbody tr {
    
    height: 68px;
}

.table tbody tr:nth-of-type(even) {
    border-top: 1px solid #dddddd;
    background-color: #F7F7F8;
}

/* .table tbody tr:last-of-type {
    border-bottom: 3px solid #f3f3f3;
} */

.table tbody tr:hover {
    background: linear-gradient(90.94deg, #f6c7b4 31.54%, #fabba2 74.58%);
    cursor: pointer;
    color: #ffffff;
}

.userInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.createUserButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
    width: 210px;
    height: 50px;
    background: linear-gradient(0deg, #f58f6b, #f58f6b), #ffffff;
    border-radius: 5px;
    cursor: pointer;
    gap: 7px;
}
.title {
    font-family: 'Montserrat';
    font-style: normal;
    color: #555555;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 17px 30px;
}
.datePicker {
    width: 30%;
    margin-right: 20px;
    height: 50px;
    margin-bottom: 10px;
}
.wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
