.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: 100%;
  background-color: #ffffff;
  padding: 0 16px 10px 29px;
  margin-top: 15px;
}
.wrap {
  width: 33%;
}
.select {
  width: 100%;
}
.formInfo {
  display: flex;
  gap: 12px;
  align-items: center;
  color: #747474;
  font-size: 16px;
  width: 100%;
  margin-bottom: 8px;
  color: #555555;
}
.formInfo p {
  white-space: nowrap;
}
.icon {
  width: 22px;
  height: 22px;
}
.datePicker {
  height: 50px;
}
.textHelper {
  color: tomato;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
}
