.mainContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 750px;
    min-height: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Montserrat';
    border-radius: 5px;
    outline: 0px;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(161.79deg, #fbb59a -34.78%, #ffdbcd 150.13%);
    height: 75px;
    width: 100%;
    color: #ffffff;
    font-size: 20px;
    padding: 20px;
    user-select: none;
}

.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    gap: 30px;
    width: 100%;
    background-color: #ffffff;
    padding-bottom: 40px;
    height: 100%;
    overflow: auto;
}
.formContainer::-webkit-scrollbar {
    width: 3px;
}
.formContainer::-webkit-scrollbar-thumb {
    background: #FBBFA6;
    border-radius: 5px;
}

.formRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 35px;
    padding-right: 35px;
}

.formInfo {
    display: flex;
    gap: 18px;
    align-items: center;
    color: #747474;
    font-size: 16px;
}

.closeIcon {
    cursor: pointer;
}

.saveButton {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 18px;
    background: linear-gradient(98.82deg, #ffc3ab -1.58%, #fe814f 123.55%);
    border: 0px solid transparent;
    border-radius: 5px;
    width: 210px;
    padding: 14px 0;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.textHelper {
    color: tomato;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
}

.inputColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 460px;
}
.icon {
    width: 20px;
    height: 20px;
}
.wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 96px;
}
.formInfoDate {
    display: flex;
    gap: 18px;
    align-items: center;
    color: #747474;
    font-size: 16px;
    width: 183px;
}
.title {
    display: flex;
    align-items: flex-start;
    color: #747474;
    font-size: 16px;
    width: calc(100% - 70px);
    margin-left: 35px;
    margin-right: 35px;
    border-bottom: 1px solid hsl(0deg 0% 33% / 20%);
    padding-bottom: 10px;
    margin-top: 10px;
}
