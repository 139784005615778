.itemMenu {
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.itemMenu span,
.active span {
    padding-left: 20px;
}

.itemMenu:hover {
    color: var(--hover-color);
    border-right: 7px solid var(--hover-color);
}

.itemMenu:hover svg>g>path,
.active svg>g>path{
    fill: var(--hover-color);
}

.itemMenu:hover svg line, .itemMenu:hover svg rect, .itemMenu:hover svg mask,
.active svg line, .active svg rect, .active svg mask{
    stroke: var(--hover-color);
}

.itemMenu:hover svg>path,
.active svg>path{
    /* fill: var(--hover-color); */
    stroke: var(--hover-color);
}

.active {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    color: var(--hover-color);
    border-right: 7px solid var(--hover-color);
}
