.block {
  width: 100%;
  height: calc(94vh - 180px);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /* padding: 12px 30px 30px 30px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.header {
  padding: 25px 20px 26px 36px;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 75px;
  background: linear-gradient(161.79deg, #fbb59a -34.78%, #ffdbcd 150.13%);
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
}
.wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
}
.inputTitle {
  width: 100%;
  background: #f7f7f8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 5px;
  height: 50px;
  padding: 17px 19px 16px 19px;
  outline: none;
}
.btn {
  background: linear-gradient(98.82deg, #ffc3ab -1.58%, #fe814f 123.55%);
  border: none;
  border-radius: 5px;
  width: 295px;
  height: 55px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  cursor: pointer;
}
.wrapper {
  width: calc(100% - 96px);
  margin: 0px 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 31px;
}
.wrapper div {
  width: 100%;
}
.contentWrap {
  margin-top: 35px;
  display: flex;
  gap: 33px;
  height: 100%;
}
.inputContent {
  width: 100%;
  background: #f7f7f8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 5px;
  height: 100%;
  padding: 17px 19px 16px 19px;
  outline: none;
  resize: none;
}
.inputsWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wrapTitle {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.wrapContent {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
  height: 100%;
  margin-bottom: 30px;
}
.textHelper {
  color: tomato;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
}

.textHelperImg {
  color: tomato;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
}
