.wrapper {
    height: 100vh;
    width: 100vw;
    background-image: linear-gradient(
        156.46deg,
        #ffba9e 8.39%,
        #ffe5da 103.31%
    );
    display: flex;
    align-items: center;
    flex-direction: column;
}

.box {
    height: 515px;
    width: 720px;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 45px 69px 47px 76px;
}

.logo {
    margin: 70px 0 40px 0;
}

.box p {
    text-align: center;
}

.greet {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #555555;
    margin-bottom: 9px;
}

.restore {
    margin-bottom: 38px;
    text-decoration: underline;
    color: var(--main-text-color);
    font-size: 14px;
    align-self: center;
    cursor: pointer;
    z-index: 999;
}

.title {
    font-size: 16px;
    margin-bottom: 24px;
}

.optional {
    font-size: 14px;
}
.optional a {
    text-decoration: none;
}

.form {
    display: flex;
    flex-direction: column;
    z-index: 9999;
}

.form div {
    text-align: center;
}

label {
    margin: 5px 0 0 5px;
}

.textInput,
.passwordInput {
    width: 100%;
    padding: 12px 20px;
    margin: 10px 0;
    display: inline-block;
    border: 1px solid #fbfbfb;
    border-radius: 5px;
    box-sizing: border-box;
    background: #fbfbfb;
    z-index: 9999;
}

.textInput:focus,
.passwordInput:focus {
    border: 1px solid #fbfbfb;
    outline: none;
}

.textInput::placeholder,
.passwordInput::placeholder {
    color: var(--input-text-color);
    font-family: 'Montserrat', sans-serif;
}

.passwordInput {
    -webkit-text-security: disc;
}

.submitButton {
    width: 295px;
    background: linear-gradient(98.82deg, #ffc3ab -1.58%, #fe814f 123.55%);
    color: white;
    padding: 20px 0px;
    margin: 20px 0 9px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition-duration: 0.4s;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 600;
    align-self: center;
    text-align: center;
    z-index: 9999;
}

.submitButton:hover {
    background: linear-gradient(133.29deg, #ffba9e -28.13%, #ffe5da 192.16%);
    opacity: 0.4;
    border-radius: 5px;
}

.textHelper {
    color: tomato;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
}

.leftFrame {
    position: absolute;
    top: 50%;
    left: 1%;
}

.rightFrame {
    position: absolute;
    top: 58%;
    right: 21%;
}

@media only screen and (max-width: 700px) {
    .leftFrame,
    .rightFrame {
        display: none;
    }

    .box {
        height: 515px;
        width: 400px;
        padding: 45px 20px 47px 20px;
    }
}
