.mainArea {
  width: 100%;
  height: 94vh;
  margin-top: 30px;
}
.block {
  width: 100%;
  height: calc(94vh - 180px);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 12px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.videosContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  overflow: auto;
}
.videosContainer::-webkit-scrollbar {
  width: 3px;
}
.videosContainer::-webkit-scrollbar-thumb {
  background: #fbbfa6;
  border-radius: 5px;
}
.tabs {
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-between;
  margin-bottom: 16px;
}
.tabs button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #55555580;
  border: none;
  background: none;
  cursor: pointer;
  text-align: center;
  height: 30px;
  padding-bottom: 5px;
}
.active {
  color: #555555 !important;
  padding-bottom: 0 !important;
  border-bottom: 5px solid #d9714e !important;
}
.wrapper {
  background: #f7f7f8;
  border-radius: 5px;
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: space-between;
  padding: 21px 15px 17px 25px;
  align-items: center;
}
.wrap {
  display: flex;
  gap: 52px;
  width: 90%;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
  flex-wrap: wrap;
  width: calc(100% - 222px);
}
.image {
  width: 170px;
  height: 92px;
  border-radius: 5px;
}
.buttons {
  display: flex;
  gap: 25px;
  cursor: pointer;
}
.addBtn {
  width: 295px;
  height: 55px;
  background: linear-gradient(98.82deg, #ffc3ab -1.58%, #fe814f 123.55%);
  border: none;
  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin-top: 20px;
}
/* svg {
    cursor: pointer;
} */
