.app {
    width: 100vw;
    height: 100vh;
    background-color: #FFFDFD;
}

.container {
    max-width: 1600px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    gap: 40px;
}