.paginationArea {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 100%;
}

.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9em;
  min-width: 400px;
  overflow: hidden;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 18px;
  padding-left: 29px;
}

.tableColumn th {
  padding: 20px 8px;
}

.tableColumn th:first-child {
  padding-left: 22px;
}

.table thead tr {
  background: #f5c5b2;
  box-shadow: 0px 1px 8px rgb(0 0 0 / 25%);
  color: #ffff;
  text-align: left;
  font-weight: bold;
  transition: 0.3s;
}

.tableBody td {
  padding: 10px 8px;
}

.tableBody td:first-child {
  padding-left: 22px;
}

.table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table tbody tr:nth-of-type(odd) {
  background-color: #fbfbfb;
}

.table tbody tr:last-of-type {
  border-bottom: 3px solid #f3f3f3;
}

.table tbody tr:hover {
  background: linear-gradient(90.94deg, #f6c7b4 31.54%, #fabba2 74.58%);
  cursor: pointer;
  color: #ffffff;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.createUserButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  width: 210px;
  height: 50px;
  background: linear-gradient(0deg, #f58f6b, #f58f6b), #ffffff;
  border-radius: 5px;
  cursor: pointer;
  gap: 7px;
}
.title {
  font-family: "Montserrat";
  font-style: normal;
  color: #555555;
  /* font-weight: 600; */
  font-size: 18px;
  text-align: right;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.datePicker {
  width: 30%;
  margin-right: 20px;
  height: 50px;
  margin-bottom: 10px;
}
.wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 0px;
}
.activeArrow {
  transform: translate(0, 100%) rotate(-180deg);
  position: absolute;
  top: 12px;
  right: 30px;
}
.selectWrap {
  padding: 0 13px 0 28px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 295px;
  height: 40px;
  margin: 0 16px 0 29px;
  color: #555555;
  align-items: center;
}
