.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 130px;
}

.searchArea {
    display: flex;
    align-items: center;
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: var(--hover-color);
}

.logoutButton {
    cursor: pointer;
}
