.wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 48px;
    width: 100%;
    background-color: #ffffff;
    padding: 0 16px 0px 29px;
    margin-top: 27px;
}
.formInfo {
    display: flex;
    gap: 12px;
    align-items: center;
    color: #747474;
    font-size: 16px;
    width: 100%;
    margin-bottom: 8px;
    color: #555555;
}
.datePicker {
    width: 100%;
    background: #F7F7F8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.datePicker fieldset{
    border: none;
    outline: none;
}
