.previousIcon,
.nextIcon {
    width: 20px;
    height: 60px;
}

.nextButton,
.previousButton {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    color: #6D6D6D;
}