.container {
    background: #FFFFFF;
    box-shadow: inset -2px 0px 7px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow: auto;
    padding: 15px 13px 15px 22px;
}
.container::-webkit-scrollbar {
    width: 3px;
}
.container::-webkit-scrollbar-thumb {
    background: #FBBFA6;
    border-radius: 5px;
}
.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #555555;
    margin-bottom: 30px;
}
.input {
    margin-bottom: 5px;
}
.formInfo {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #555555;
    font-size: 16px;
    width: 100%;
}
.formRow {
    display: flex;
    align-items: flex-start;;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    position: relative;
}
.icons {
    width: 22px;
    height: 22px;
}
.wrap {
    width: 100%;
    background: #F7F7F8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #555555;
    padding: 15px 13px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 25px;
    font-family: 'Montserrat';
    font-style: normal;
    height: 85px;
}
.wrapCategory {
    width: 100%;
    background: #F7F7F8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #555555;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 25px;
    font-family: 'Montserrat';
    font-style: normal;
}
.wrapCategory span {
    padding: 15px 13px;
}
.wrap span:nth-child(1) {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}
.wrap span:nth-child(2) {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
}
.line {
    width: 100%;
    border: 0.5px solid #555555;
    margin-top: 40px;
    display: flex;
    opacity: 0.4;
    margin-bottom: 40px;
}
.commission {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #555555;
    margin-top: 13px;
}
.wrapper {
    background: linear-gradient(98.82deg, #FFC3AB -1.58%, #FE814F 123.55%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #FFFF;
    padding: 15px 11px;
    display: flex;
    margin-top: 30px;
}
.wrapIcon {
    background-color: #FFFF;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    position: relative;
}
.icon {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 24%;
    left: 24%;
}
.titlesWrap {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    gap: 5px;
    font-family: 'Montserrat';
    font-style: normal;
}
.titlesWrap span:nth-child(1) {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
.titlesWrap span:nth-child(2) {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}
.inputWrap {
    position: relative;
    width: 100%;
}
.iconClose {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 24px;
    right: 10px;
    cursor: pointer;
}
.wrapSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wrapSelect span {
    color: #555555;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.select {
    background-color: #FFFF;
    width: 65%;
}
/* td {
    padding: 15px 13px;
    height: 30px;
}
.table tbody tr:hover {
    background: #E6E6E6;
    cursor: pointer;
    color: #555555;
}
.tableBody td:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #555555;
}
.tableBody td {
    font-weight: 400;
} */

.table {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.9em;
    min-width: 400px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-left: 29px;
    border-radius: 0px 0px 5px 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.tableColumn th {
    padding: 20px 8px;
}

.tableColumn th:first-child {
    padding-left: 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #555555;
}

.tableBody td {
    padding: 10px 8px;
    font-weight: 400;
}

.tableBody td:first-child {
    padding-left: 22px;
    font-weight: 600;
}

.table tbody tr:nth-of-type(odd) {
    background-color: #F7F7F8;
}

.table tbody tr:last-of-type {
    border-bottom: 3px solid #f3f3f3;
}

.table tbody tr:hover {
    background: #E6E6E6;
    cursor: pointer;
}