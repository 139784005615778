.mainArea {
    width: 100%;
    height: 94vh;
    margin-top: 30px;
}
.block {
    width: 100%;
    height: calc(94vh - 130px);
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 30px 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
}
.textBlock {
    background: #F7F7F8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    padding: 26px 58px;
    margin-bottom: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}
.textBlock h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #555555;
}

.block::-webkit-scrollbar {
    width: 3px;
}
.block::-webkit-scrollbar-thumb {
    background: #FBBFA6;
    border-radius: 5px;
}
.btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #D9714E;
    border: none;
    background: none;
    border-bottom: 1px dashed #D9714E;
    cursor: pointer;
}
.wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}