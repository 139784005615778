@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');

:root {
  --main-text-color: #999999;
  --second-text-color: #6B6B6B;
  --input-text-color: #C5C5C5;
  --hover-color: #EB977A;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  color: var(--main-text-color);
  font-family: 'Montserrat', sans-serif;
  overflow: clip;
}

a {
  text-decoration: none;
}
