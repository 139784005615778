.contextItem {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
    font-family: 'Montserrat';
    font-weight: 500;
}

.itemIcon {
    width: 22px;
    height: 22px;
}
