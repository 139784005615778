.mainArea {
    width: 100%;
    height: 94vh;
    margin-top: 30px;
    overflow: auto;
    padding-left: 10px;
    padding-bottom: 10px;
}
.mainArea::-webkit-scrollbar {
    width: 3px;
}
.mainArea::-webkit-scrollbar-thumb {
    background: #FBBFA6;
    border-radius: 5px;
}
.blockArea {
    display: flex;
    width: 100%;
}
.blockWrap {
    width: 100%;
    background: #FFFFFF;
    box-shadow: -1px 2px 7px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: relative;
}
.tabs {
    display: flex;
    flex-direction: row;
    width: 200px;
    justify-content: space-between;
    margin-bottom: 16px;
}
.tabs button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #55555580;
    border: none;
    background: none;
    cursor: pointer;
    text-align: center;
    height: 30px;
    padding-bottom: 5px;
}
.active {
    color: #555555!important;
    padding-bottom: 0!important;
    border-bottom: 5px solid #D9714E!important;
}